import * as React from 'react';
import ReactDOM from "react-dom/client";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

// Temas y colores de la app
import { useTheme } from '@mui/material/styles';

// contextos plantillas
import { ContextTemplateProvider } from "./components/react-components/ContextTemplate";


import './404.css';

const App = () => {

    const theme = useTheme();

    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'white',
            background: `linear-gradient( white, 80%, ${theme.palette.primary.main})`,
            textAlign: 'center'
        }}>
            {/* <Typography sx={{ color: 'red', fontSize:'20vh', fontFamily:'Catamaran' }} variant="h1" component="div" >
        404
      </Typography>
      <SentimentVeryDissatisfiedIcon sx={{ m: 3, fontSize: '10rem' }} />
      <Typography variant="h1" component="div" >
        Ooups,  Página no encontrada
      </Typography>
      <Typography variant="subtitle1" component="div">
        Lo sentimos, parece que estás intentando acceder a una página que no existe en nustros servidores
      </Typography> */}

            <section className="error-container">
                <span><span style={{color:theme.palette.primary.main}}>4</span></span>
                <span style={{color: theme.palette.error.main}}>0</span>
                <span><span style={{color: theme.palette.primary.main}}>4</span></span>
            </section>
            {/* <h1>Ooups, Página no encontrada</h1> */}
            <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', color: "text.primary" }}>
                Ooups,  Página no encontrada
            </Typography>
            <Typography variant="subtitle1" component="div" sx={{  }}>
                Lo sentimos, parece que estás intentando acceder a una página que no existe en nustros servidores
            </Typography>

        </Box>
    );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        {/* <ThemeProvider theme={theme}>
            <CssBaseline />
            
        </ThemeProvider> */}
      
        <ContextTemplateProvider>
            <App />
        </ContextTemplateProvider>
    </React.StrictMode>
);